import React from 'react';
import { Modal } from 'react-bootstrap';

class VerInversionista extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGet: [],
            nombre: '',
            email: '',
            telefono: '',
            proyecto: '',
            inversion: '',
            proyectos: []
        }
    }

    componentDidMount = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectProyectos' })
          };
        const response = await fetch('http://admin.garteel.com/php/DB/inversionistas.php', requestOptions);
        const data = await response.json();
        this.setState({
            dataGet : data
        })
    }

    select = async () => {
        this.componentDidMount();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectWhere', idInversionista: this.props.idInversionista })
          };
          const response = await fetch('http://admin.garteel.com/php/DB/inversionistas.php', requestOptions);
          const data = await response.json();
          console.log(data);
          this.setState({
            nombre: data.nombre,
            email: data.correo,
            telefono: data.telefono,
            proyectos: data.proyectos
          })
    }

    numberWithCommas(x) {
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    hideModal = () => {
        this.props.hideModal();
        this.setState({
            nombre: '',
            email: '',
            telefono: '',
            dataGet: []
        })
    }

    render() { 
        return ( 
            <>
                <Modal show={this.props.show} onHide={this.hideModal} centered onShow={this.select}>
                    <Modal.Header className='d-flex justify-content-center'>
                        <h5>Ver Cliente</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <label>Nombre Completo</label>
                                    <input type="text" name='nombre' className='form-control mb-2' value={this.state.nombre} readOnly/>
                            </div>
                            <div className="col-6">
                                <label>Correo Electrónico</label>
                                    <input type='email' name='email' className="form-control mb-2" value={this.state.email} readOnly/>
                            </div>
                            <div className="col-6">
                                <label>Número Telefónico</label>
                                    <input type='tel' name='telefono' className="form-control mb-2" value={this.state.telefono} readOnly/>
                            </div>
                            <div className="col-12 text-center">
                                <label>Acceso a proyectos de inversión</label>
                                <hr className='m-1 p-0'/>
                            </div>
                                <div className="col-12">
                                    <table className="table table-striped tablaAdministracion mt-3">
                                        <thead className='thead'>
                                            <tr>
                                                <th style={{ width: '50%' }}>
                                                    Proyecto
                                                </th>
                                                <th style={{ width: '20%' }}>
                                                    Inversión
                                                </th>
                                                <th className='text-center' style={{ width: '30%' }}>
                                                    Acciones
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.proyectos[0] ?
                                            this.state.proyectos.map((e, index) =>
                                                <tr key={index}>
                                                    <td className='align-middle'>
                                                        {e.nombre}
                                                    </td>
                                                    <td className='align-middle'>
                                                       ${this.numberWithCommas(e.inversion)}
                                                    </td>
                                                    <td>
                                                        <button className='btn btn-sm btn-secondary' style={{ width: '48%' }} type='button' name={e.idProyecto} value={e.inversion} disabled>Editar</button>
                                                        <button className='btn btn-sm btn-danger ms-1' style={{ width: '48%' }} type='button' name={e.idProyecto} disabled>Borrar</button>
                                                    </td>
                                                </tr>
                                            )
                                            :
                                            <tr>
                                                <td colSpan='3' className='text-center'>Agregue elementos a la lista</td>
                                            </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                        </div>
                        {this.state.error && 
                            <p className='error mt-2 mb-0 w-100 bg-danger text-center py-2' style={{ color: 'white', borderRadius: '5px' }}>{this.state.error}</p>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-secondary' onClick={this.hideModal}>Cerrar</button>
                    </Modal.Footer>
                </Modal>
            </>
         );
    }
}
 
export default VerInversionista;