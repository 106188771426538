import React from 'react';
import VerInversionista from './VerInversionista';
import EditarInversionista from './EditarInversionista';

class TablaInversionistas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disableBtn: false,
            showModalVer: false,
            showModalEditar: false,
            idInversionista: ''
        }
    }

    componentDidMount = () => {

    }

    abrirModalVer = (e) => {
        this.setState({
            showModalVer: true,
            idInversionista: e.target.id
        })
    } 

    cerrarModalVer = () => {
        this.setState({
            showModalVer: false,
            idInversionista: ''
        })
    }

    abrirModalEditar = (e) => {
        this.setState({
            showModalEditar: true,
            idInversionista: e.target.id
        })
    } 

    cerrarModalEditar = () => {
        this.setState({
            showModalEditar: false,
            idInversionista: ''
        })
    }

    borrarInversionista = async (e) => {
        if (window.confirm("¿Eliminar Inversionista?")) {
            const requestOptionsFB = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: e.target.name })
            };
            await fetch('https://us-central1-garteel-816a5.cloudfunctions.net/app/api/delete', requestOptionsFB);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'delete', idInversionista: e.target.id })
            };
            await fetch('http://admin.garteel.com/php/DB/inversionistas.php', requestOptions);
            this.props.actualizarTabla();
          }
    }

    render() { 
        return ( 
            <>
                <div className="container">
                    <table className="table table-striped mt-3 tablaAdministracion">
                        <thead className="thead">
                            <tr>
                                <th>Nombre del Cliente</th>
                                <th>Correo</th>
                                <th>Teléfono</th>
                                <th className="text-center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.stateData.map(e =>
                                <tr key={e.idInversionista}>
                                    <td>{e.nombre}</td>
                                    <td>{e.correo}</td>
                                    <td>{e.telefono}</td>
                                    <td className="text-center" style={{width: '12%'}}>
                                        <button  id={e.idInversionista} className="btn btn-sm btn-dark btnMore mx-1" variant="primary" onClick={this.abrirModalVer} disabled={this.state.disableBtn}>
                    
                                        </button>
                                        <button  id={e.idInversionista} className="btn btn-sm btn-secondary btnEdit mx-1" variant="primary" onClick={this.abrirModalEditar} disabled={this.state.disableBtn}>
                    
                                        </button>
                                        <button id={e.idInversionista} name={e.correo} className="btn btnDelete btn-danger mx-1" disabled={this.state.disableBtn} onClick={this.borrarInversionista}>
                    
                                        </button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <VerInversionista show={this.state.showModalVer} hideModal={this.cerrarModalVer} idInversionista={this.state.idInversionista}/>
                <EditarInversionista show={this.state.showModalEditar} hideModal={this.cerrarModalEditar} idInversionista={this.state.idInversionista} actualizarTabla={this.props.actualizarTabla}/>
            </>
         );
    }
}
 
export default TablaInversionistas;