import React from 'react';
import { Modal } from 'react-bootstrap';

class RegistrarFase extends React.Component {
    constructor(props) {
        super(props);
        this.img = React.createRef()
        this.state = {
            titulo: '',
            porcentaje: 0,
            descripcion: '',
            urlImg: '',
            img: ''
        }
    }

    hideModal = () => {
        this.props.closeModalRegistrar();
        this.setState({
            titulo: '',
            porcentaje: 0,
            descripcion: '',
            urlImg: '',
            img: ''
        })
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeImg = () => {
        this.setState({
            urlImg : URL.createObjectURL(this.img.current.files[0])
        })
    }

    guardar = async () => {
        const actualizar = () => { this.props.actualizar() };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              operacion: 'insert', 
              fas_idProyecto: this.props.idProyecto,
              titulo: this.state.titulo,
              porcentaje: this.state.porcentaje,
              descripcion: this.state.descripcion,
            })
          };
        const response = await fetch('http://admin.garteel.com/php/DB/fasesproyectos.php', requestOptions);
        const data = await response.json();
        var idFase = data.idFase;
        const formData = new FormData();
        formData.append('img',this.img.current.files[0]);
        formData.append('idFase', idFase);
        var xhr = new XMLHttpRequest();
        xhr.open('POST','https://inversionistas.garteel.com/php/imagenFase.php', true);
        xhr.onload = async function () {
            var dataImg = JSON.parse(this.responseText);
            if (dataImg.img) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        operacion: 'updateImagen',
                        imagen: dataImg.img,
                        idFase: idFase,
                    })
                    };
                const response = await fetch('http://admin.garteel.com/php/DB/fasesproyectos.php', requestOptions);
                const data = await response.json();
                console.log(data);
            }
            actualizar();
        }
        xhr.send(formData);
        this.hideModal();
    }

    render() { 
        return ( 
        <>
            <Modal show={this.props.show} onHide={this.hideModal} size='lg'>
                    <Modal.Header>
                        <h3>Editar Fase</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-6">
                                <label className='labelRegistro'>Título</label>
                                    <input type="text" className='form-control' name='titulo' value={this.state.titulo} onChange={this.onChangeValue}/>
                            </div>
                            <div className="col-6">
                                <label className='labelRegistro'>Porcentaje</label>
                                    <select name="porcentaje" className='form-control' value={this.state.porcentaje} onChange={this.onChangeValue}>
                                        <option value="0">0%</option>
                                        <option value="10">10%</option>
                                        <option value="20">20%</option>
                                        <option value="30">30%</option>
                                        <option value="40">40%</option>
                                        <option value="50">50%</option>
                                        <option value="60">60%</option>
                                        <option value="70">70%</option>
                                        <option value="80">80%</option>
                                        <option value="90">90%</option>
                                        <option value="100">100%</option>
                                    </select>
                            </div>
                            <div className="col-12 mt-3">
                                <label className='labelRegistro'>Descripción</label>
                                    <textarea className='form-control' name="descripcion" cols="30" rows="5" value={this.state.descripcion} onChange={this.onChangeValue}/>
                            </div>
                            <div className="col-12 mt-3">
                                <label className='labelRegistro'>Imagen</label>
                                    <input type="file" className='form-control' ref={this.img} accept="image/png, image/jpg, image/jpeg" onChange={this.onChangeImg}/>
                                    <div className="text-center">
                                        <img src={this.state.urlImg} alt="" style={{ maxHeight: '400px' }} className='my-3'/>
                                    </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='button' className='btn btn-secondary' onClick={this.hideModal}>Cancelar</button>
                        <button type='button' className='btn btn-success' onClick={this.guardar}>Guardar</button>
                    </Modal.Footer>
                </Modal>   
        </> 
        );
    }
}
 
export default RegistrarFase;