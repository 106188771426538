import React from 'react';
import { useState } from 'react';
import EditarUsuario from './EditarUsuario';
import { useEffect } from 'react';


function TablaUsuarios(props) {

    const [show, setShow] = useState(false);
    const [idUsuario, setID] = useState(null);
    const [disableBtn, setdisableBtn] = useState(false);

    const cerrarModal = () => setShow(false);
    const arbirModal = (e) => {
        setShow(true);
        setID(e.target.id);
    }

    const borrarUsuario = async (e) => {
        if (window.confirm("¿Eliminar usuario?")) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'delete', idUsuario: e.target.id })
            };
            const response = await fetch('http://admin.garteel.com/php/DB/usuarios.php', requestOptions);
            const data = await response.json();
            console.log(data);
            props.actualizarTabla();
          }
    }

    useEffect(() => {
        if (props.permisos === '1') {
            setdisableBtn(false);
        }else{
            setdisableBtn(true);
        }
    }, [props]);

        return (
            <>
            <div className="container">
                <table className="table table-striped mt-3 tablaAdministracion">
                    <thead className="thead">
                        <tr>
                            <th>Nombre</th>
                            <th>Apellidos</th>
                            <th>Email</th>
                            <th>Tipo de usuario</th>
                            <th className="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.stateData.map(e =>
                            <tr key={e.idUsuario}>
                                <td>{e.nombre}</td>
                                <td>{e.apellidos}</td>
                                <td>{e.email}</td>
                                <td>{e.tipoUsuario}</td>
                                <td className="text-center" style={{width: '15%'}}>
                                    <button  id={e.idUsuario} className="btn btn-sm btn-secondary btnEdit mx-1" variant="primary" onClick={arbirModal} disabled={disableBtn}>
                
                                    </button>
                                    <button id={e.idUsuario} className="btn btnDelete btn-danger mx-1" onClick={borrarUsuario} disabled={disableBtn}>
                
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <EditarUsuario show={show} cerrarModal={cerrarModal} idUsuario={idUsuario} actualizarTabla={props.actualizarTabla}/>
            </>
        )
}
 
export default TablaUsuarios;