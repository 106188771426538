import React from 'react';
import Navbar from '../NavBar';
import { MyContext } from '../../contexts/MyContext';
import Login from '../Login';
import TarjetaProyecto from './TarjetaProyecto';
import RegistrarProyecto from './RegistrarProyecto';

class Inversiones extends React.Component {
    static contextType = MyContext;

    constructor(){
        super();
        this.state = {
            proyectos: [],
            showModalReg: false,
        }
    }

    componentDidMount = async () => {
        document.title = 'Inversiones - AdminGarteel';
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
          };
          const response = await fetch('http://admin.garteel.com/php/DB/proyectos.php', requestOptions);
          const data = await response.json();
          console.log(data);
          this.setState({
              proyectos: data
          })
    }

    cerrarModal = () => {
        this.setState({
            showModalReg: false
        });
    }

    abrirModal = () => {
        this.setState({
            showModalReg: true
        })
    }

    actualizar = () => {
        this.componentDidMount();
    }

    render() { 
        if(this.context.rootState.isAuth === true){
            return <>
                <Navbar/>
                    <div className="container-fluid">
                        <div className="row botoneraUsuarios bg-light">
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-outline-success my-2 btnUsuarios" onClick={this.abrirModal}>Agregar</button>
                            </div>    
                        </div>
                        <div className="row d-flex justify-content-center mt-3">
                            {this.state.proyectos.map((e, index) =>
                                <TarjetaProyecto key={index} data={e} actualizar={this.actualizar}/>
                            )}
                        </div>
                    </div>
                    <RegistrarProyecto show={this.state.showModalReg} cerrarModal={this.cerrarModal} actualizar={this.actualizar}/>
                </>;
            }else{
                return (
                    <Login/>
                )
            }
    }
}
 
export default Inversiones;