import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Modal } from 'react-bootstrap';
import '../../css/tarjetaproyecto.css';

class TarjetaProyecto extends React.Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
            showModalEditar: false,
            showModalAvances: false
        }
    }

    componentDidMount = () => {

    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    abrirModal = () => {
        this.setState({
            showModal: true
        })
    }

    abrirModalEditar = () => {
        this.setState({
            showModalEditar: true,
            showModal: false
        })
    }
    
    abrirModalAvances = () => {
        this.setState({
            showModalAvances: true,
            showModal: false
        })
    }

    cerrarModal = () => {
        this.setState({
            showModal: false
        })
    }
    
    cerrarModalEditar = () => {
        this.setState({
            showModalEditar: false
        })
    }
    
    cerrarModalAvances = () => {
        this.setState({
            showModalAvances: false
        })
    }

    diasFaltantes = (fecha) => {
        let fechaActual = new Date();
        let fechaLimite = new Date(fecha + ' 00:00');
        var one_day=1000*60*60*24;
        return Math.ceil((fechaLimite.getTime()-fechaActual.getTime())/(one_day));
    }

    barraProgreso = () => {
        var porcentaje = (Number(this.props.data.dineroRecaudado)/Number(this.props.data.dineroARecaudar)*100).toFixed(0);
        var gradient = '';
        if (porcentaje < 50) {
            gradient = 'linear-gradient(to right, #7C9A92 ' + porcentaje + '%, white ' + porcentaje + '%)';
        }else{
            gradient = 'linear-gradient(to right, #7C9A92 ' + porcentaje + '%, white ' + (100 - Number(porcentaje)) + '%)';
        }
        return gradient
    }

    borrarProyecto = async () => {
        if (window.confirm("¿Eliminar proyecto?")) {
            const requestOptionsArchivos = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'deleteProyectos', idProyecto: this.props.data.idProyecto })
            };
            const responseArchivos = await fetch('https://www.garteel.com/php/DB/borrarArchivo.php', requestOptionsArchivos);
            const dataArchivos = await responseArchivos.json();
            console.log(dataArchivos);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'delete', idProyecto: this.props.data.idProyecto })
            };
            const response = await fetch('http://admin.garteel.com/php/DB/proyectos.php', requestOptions);
            const data = await response.json();
            console.log(data);
            alert(data.mensaje);
            this.props.actualizar();
          }
    }
    
    render() { 
        return ( 
            <>
                <div className="col-sm-6 col-lg-4 col-xxl-3 my-2 tarjetaProyecto">
                    <Card>
                        <CardActionArea onClick={this.abrirModal}>
                            <CardContent style={{ backgroundColor: '#7C9A92' }}>
                                <Typography gutterBottom variant='h6' component="div" style={{ height: '20px', color: 'white' }} className='text-center'>
                                    {this.props.data.nombre}
                                </Typography>
                            </CardContent>
                            <CardMedia
                            component="img"
                            width="100"
                            image={"https://www.garteel.com/img/proyectos/" + this.props.data.imgP}
                            alt="Proyecto"
                            className='imagenTarjeta'
                            />
                            <CardContent className='text-center pb-1 pt-0 px-0'>
                                    <div className="row p-0">
                                        <div className="col-12 p-0">
                                            <div className='d-flex justify-content-center' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso() }}>
                                                <p className='p-0 m-0 porcentaje'>
                                                    {(Number(this.props.data.dineroRecaudado)/Number(this.props.data.dineroARecaudar)*100).toFixed(0) + '%'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                <div className="row py-1 px-3" style={{ borderTop: '1px solid rgb(0 0 0 / 12%)' }}>
                                    <div className="col-6">
                                        <p className='m-0 p-0' style={{ fontSize: '12px' }}>Dinero a Recaudar</p>
                                        <div style={{ color: '#7C9A92' }}>{'$' + this.numberWithCommas(this.props.data.dineroARecaudar)}</div>
                                    </div>
                                    <div className="col-6">
                                        <p className='m-0 p-0' style={{ fontSize: '12px' }}>Dinero Recaudado</p>
                                        <div style={{ color: '#7C9A92' }}>{'$' + this.numberWithCommas(this.props.data.dineroRecaudado)}</div>
                                    </div>
                                </div>
                                <div className="row text-center pt-1 px-3" style={{ borderTop: '1px solid rgb(0 0 0 / 12%)', fontSize: '12px' }}>
                                    <div className="col-12">
                                        Rendimiento estimado del proyecto:
                                    </div>
                                </div>
                                <div className="row px-3" style={{ color: '#7C9A92' }}>
                                    <div className="col-6 d-flex justify-content-center align-items-end">
                                        <div style={{ fontSize: '20px' }}>{this.props.data.tirGarantizado}</div><div className='pb-1'> %</div>
                                    </div>
                                    <div className="col-6 d-flex justify-content-center align-items-end">
                                        <div style={{ fontSize: '20px' }}>{this.props.data.tirEsperado}</div><div className='pb-1'> %</div>
                                    </div>
                                </div>
                                <div className="row pb-1 px-3">
                                    <div className="col-6">
                                        <div style={{ fontSize: '12px' }}>Garantizado</div>
                                    </div>
                                    <div className="col-6">
                                        <div style={{ fontSize: '12px' }}>Esperado</div>
                                    </div>
                                </div>
                                <div className="row py-1 px-3" style={{ borderTop: '1px solid rgb(0 0 0 / 12%)' }}>
                                    <div className="col-6">
                                        <p className='mb-1' style={{ textAlign: 'left' }}>Mínimo de inversión:</p>
                                    </div>
                                    <div className="col-6">
                                        <p className='mb-1' style={{ textAlign: 'right' }}>{'$' + this.numberWithCommas(this.props.data.minInversion)}</p>
                                    </div>
                                    <div className="col-6">
                                        <p style={{ textAlign: 'left' }} className='mb-0'>Plazo estimado:</p>
                                    </div>
                                    <div className="col-6">
                                        <p style={{ textAlign: 'right' }} className='mb-0'>{this.props.data.plazo + ' año(s)'}</p>
                                    </div>
                                </div>
                                <div className="row pt-2 pb-1 px-3" style={{ borderTop: '1px solid rgb(0 0 0 / 12%)' }}>
                                    <div className="col-12" style={{ display: 'inline' }}>
                                        <div style={{ display: 'inline' }}>Estatus: </div><div style={{ color: '#7C9A92', display: 'inline' }}>{(this.diasFaltantes(this.props.data.fechaLimiteInversion) > 0) ? <div style={{ display: 'inline' }}>Activo</div> : <div style={{ display: 'inline' }}>Inactivo</div> }</div>
                                    </div>
                                </div>
                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid rgb(0 0 0 / 12%)' }}>
                                    <div className="col-12">
                                        {this.props.data.ubicacion}
                                    </div>
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
                <Modal show={this.state.showModal} onHide={this.cerrarModal} centered>
                    <Modal.Header className='d-flex justify-content-center'>
                            <h5>{this.props.data.nombre}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-6">
                                <a className='btn btn-success form-control' href={'/inversiones/' + this.props.data.idProyecto + '-' + this.props.data.nombreLink}>Editar</a>
                            </div>
                            <div className="col-6">
                                <button className='btn btn-danger form-control' onClick={this.borrarProyecto}>Borrar</button>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-flex justify-content-center'>
                        <button className='btn btn-secondary' onClick={this.cerrarModal}>Cancelar</button>
                    </Modal.Footer>
                </Modal>
            </>
         );
    }
}
 
export default TarjetaProyecto;