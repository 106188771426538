import React from 'react';
import { BrowserRouter as Switch, Route} from 'react-router-dom';
import Login from './Login';
import Inicio from './Inicio';
import Usuarios from '../components/Administracion/Usuarios/Usuarios';
import Vender from './Vender/Vender';
import Inversiones from './Inversiones/Inversiones';
import Inversionistas from './Inversionistas/Inversionistas';
import Proyecto from './Inversiones/Proyecto';
import Proyectos from './Proyectos/Proyectos';

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      linksProyectos: []
    }
  }

  componentDidMount = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ operacion: 'selectLinks' })
    };
    const responseProy = await fetch('http://admin.garteel.com/php/DB/proyectos.php', requestOptions);
    const dataProy = await responseProy.json();
    this.setState({
      linksProyectos: dataProy
    })
  }

  render() { 
    return (
      <Switch>
        <Route path="/login" component={Login}/>
        <Route exact path="/" component={Inicio}/>
        <Route exact path="/usuarios" component={Usuarios}/>
        <Route exact path="/vender" component={Vender}/>
        <Route exact path="/inversiones" component={Inversiones}/>
        <Route exact path="/proyectos" component={Proyectos}/>
        {this.state.linksProyectos[0] && this.state.linksProyectos.map((e, index) => 
            <Route key={index} exact path={'/inversiones/' + e.idProyecto + '-' + e.nombreLink} render={(props) => <Proyecto {...props} idProyecto={e.idProyecto}/>}/>
          )}
        <Route exact path="/inversionistas" component={Inversionistas}/>
      </Switch>
    )
  }
}
 
export default Routes;