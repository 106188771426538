import React, { useState } from 'react';
import {Modal, Button} from 'react-bootstrap';

function EditarUsuario(props) {

  const initialState = {
    userData:{
        nombre:'',
        apellidos:'',
        email:'',
        tipoUsuario:'',
    },
    respuesta:'',
  }
  const [state,setState] = useState(initialState);

  const select = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ operacion: 'selectWhere', idUsuario: props.idUsuario })
    };
    const response = await fetch('http://admin.garteel.com/php/DB/usuarios.php', requestOptions);
    const data = await response.json();
    if (data[0].tipoUsuario === '2') {
      setState({
        ...state,
        hidePermisos: false,
        userData: data[0]
      });
    }else{
      setState({
        ...state,
        userData: data[0]
      });
    }
  }

  const editarUsuario = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        operacion: 'update', 
        idUsuario: props.idUsuario,
        nombre: state.userData.nombre,
        apellidos: state.userData.apellidos,
        email: state.userData.email,
        tipoUsuario: state.userData.tipoUsuario,
      })
    };
    const response = await fetch('http://admin.garteel.com/php/DB/usuarios.php', requestOptions);
    const data = await response.json();
    setState({
      ...state,
      respuesta: data.mensaje
    })
    props.actualizarTabla();
  } 

  const onChangeValue = (e) => {
    setState({
      ...state,
      userData:{
          ...state.userData,
          [e.target.name]:e.target.value
      }
    });
  }

  const onChangeValueUserType = (e) => {
    console.log(e.target.value);
    if (e.target.value === '2') {
        setState({
            ...state,
            hidePermisos: false,
            userData:{
                ...state.userData,
                [e.target.name]:e.target.value
            }
        });
    }else{
        setState({
            ...state,
            hidePermisos: true,
            userData:{
                ...state.userData,
                [e.target.name]:e.target.value
            }
        });
    }
}

  const cerrarModal = () => {
    props.cerrarModal();
    setState({
      ...state,
      respuesta: '',
      hidePermisos: true
    })
  }

  let respuesta = '';
  if(state.respuesta){
      respuesta = <div className="text-center mt-3">{state.respuesta}</div>;
  }

  return (
    <>
      <Modal show={props.show} onHide={cerrarModal} onShow={select}>
          <Modal.Header>
              <Modal.Title>Editar Usuario</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <form action="">
                <div className="form-control">
                  <div className="row">
                    <div className="col-6">
                      <label className="form-label">Nombre(s)</label>
                        <input type="text" name="nombre" className="form-control" value={state.userData.nombre} onChange={onChangeValue}/>
                    </div>
                    <div className="col-6">
                      <label className="form-label">Apellido(s)</label>
                        <input type="text" name="apellidos" className="form-control" value={state.userData.apellidos} onChange={onChangeValue}/>
                    </div>
                    <div className="col-12">
                      <label className="form-label">Email</label>
                        <input type="email" name="email" className="form-control" value={state.userData.email} onChange={onChangeValue}/>
                      <label className="form-label">Tipo de Usuario</label>
                        <select name="tipoUsuario" className="form-control" value={state.userData.tipoUsuario} onChange={onChangeValueUserType}>
                          <option value="">Seleccionar</option>
                          <option value="1">Administrador</option>
                          <option value="2">Empleado</option>
                        </select>
                    </div>
                  </div>
                </div>
              </form>
              {respuesta}
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={cerrarModal}>
                Cancelar
              </Button>
              <Button variant="success" onClick={editarUsuario}>
                Guardar Cambios
              </Button>
          </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditarUsuario;