import React, {useContext} from 'react';
import {MyContext} from '../contexts/MyContext';
import logo from '../img/logo.png';
import '../css/navbar.css';

function Navbar () {

    const {rootState,logoutUser} = useContext(MyContext);
    const {theUser} = rootState;

    return ( 
        <>
        <div>
        <nav className="navbar navbar-expand navbar-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="" height="40px"/>
                </a>
                <div className="collapse navbar-collapse justify-content-center">
                    <ul className="navbar-nav">
                        <li className="nav-item"><a className="nav-link active" aria-current="page" href="/">Inicio</a></li>
                        <li className="nav-item"><a className="nav-link" aria-current="page" href="/usuarios">Usuarios</a></li>
                        <li className="nav-item"><a className="nav-link" aria-current="page" href="/vender">Vender</a></li>
                        <li className="nav-item"><a className="nav-link" aria-current="page" href="/inversiones">Inversiones</a></li>
                        <li className="nav-item"><a className="nav-link" aria-current="page" href="/proyectos">Proyectos</a></li>
                        <li className="nav-item"><a className="nav-link" aria-current="page" href="/inversionistas">Clientes</a></li>
                    </ul>
                </div>
                <div className="d-flex align-items-center">
                    <span className="mx-3 navbar-text">Bienvenido, {theUser.nombre}</span>
                    <button className="btn btnSalir btn-outline-light" onClick={logoutUser}>Salir</button>
                </div>
            </div>
        </nav>
        </div>
        </>
     );
}

export default Navbar ;