import React from 'react';
import { Modal } from 'react-bootstrap';
import { auth } from '../../firebase';
import NumberFormat from 'react-number-format';

class RegistrarInversionista extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGet: [],
            proyectosConstruccion: [],
            error: null,
            nombre: '',
            email: '',
            telefono: '',
            proyecto: '',
            inversion: '',
            proyectos: [],
            proyectoC: '',
            proyectosC: [],
        }
    }

    componentDidMount = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectProyectos' })
          };
        const response = await fetch('http://admin.garteel.com/php/DB/inversionistas.php', requestOptions);
        const data = await response.json();
        this.setState({
            dataGet : data
        })
        this.selectProyectosConstruccion();
    }

    selectProyectosConstruccion = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
          };
        const response = await fetch('http://admin.garteel.com/php/DB/proyectosconstruccion.php', requestOptions);
        const data = await response.json();
        this.setState({
            proyectosConstruccion : data
        })
    } 

    numberWithCommas(x) {
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    agregarProyecto = async (e) => {
        e.preventDefault();
        if (this.state.proyecto !== '' && this.state.inversion !== '') {
            var inversionPrevia = this.state.proyectos.find(proyecto => proyecto.idProyecto === this.state.proyecto);
            if (inversionPrevia) {
                var inversionNueva = Number(inversionPrevia.inversion) + Number(this.state.inversion);
                var indexProyecto = this.state.proyectos.findIndex((proyecto => proyecto.idProyecto === this.state.proyecto));
                var listaProyectosNueva = this.state.proyectos;
                listaProyectosNueva[indexProyecto].inversion = inversionNueva;
                this.setState({
                    proyectos: listaProyectosNueva,
                    proyecto: '',
                    inversion: ''
                })
            }else{
                let nombreProyecto = this.state.dataGet.filter(e => e.idProyecto === this.state.proyecto)[0].nombre;
                let proyecto = {idProyecto: this.state.proyecto, inversion: this.state.inversion, nombre: nombreProyecto};
                let proyectos = this.state.proyectos;
                proyectos.push(proyecto);
                this.setState({
                    proyectos: proyectos,
                    proyecto: '',
                    inversion: ''
                })
            }
        }else{
            alert('Llena los campos primero')
        }
    }
    
    agregarProyectoC = async (e) => {
        e.preventDefault();
        if (this.state.proyectoC !== '') {
            if (this.state.proyectosC.find(e => e.idProyectoConstruccion === this.state.proyectoC)) {
                
            }else{
                let nombreProyecto = this.state.proyectosConstruccion.filter(e => e.idProyectoConstruccion === this.state.proyectoC)[0].nombre;
                let proyectoC = {idProyectoConstruccion: this.state.proyectoC, nombre: nombreProyecto};
                let proyectosC = this.state.proyectosC;
                proyectosC.push(proyectoC);
                this.setState({
                    proyectosC: proyectosC,
                    proyectoC: ''
                })
            }
        }else{
            alert('Llena los campos primero')
        }
    }

    editarProyecto = (e) => {
        let filteredArray = this.state.proyectos.filter((proyecto) => proyecto.idProyecto !== e.target.name);
        this.setState({
            inversion: e.target.value,
            proyecto: e.target.name,
            proyectos: filteredArray
        })
    } 

    quitarProyecto = (e) => {
        let filteredArray = this.state.proyectos.filter((proyecto) => proyecto.idProyecto !== e.target.name);
        this.setState({
            proyectos: filteredArray
        })
    }

    quitarProyectoC = (e) => {
        let filteredArray = this.state.proyectosC.filter((proyectoC) => proyectoC.idProyectoConstruccion !== e.target.name);
        this.setState({
            proyectosC: filteredArray
        })
    }

    submit = async (e) => {
        e.preventDefault();
        console.log(this.state.proyectos);
        var pass = '';
        var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$';        
        for (var i = 1; i <= 8; i++) {
            var char = Math.floor(Math.random() * str.length + 1);    
            pass += str.charAt(char)
        }
            try {
                await auth.createUserWithEmailAndPassword(this.state.email, pass);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                    operacion: 'enviarCorreo',
                    correo: this.state.email,
                    contrasena: pass,
                    })
                };
                const response = await fetch('http://admin.garteel.com/php/avisoinversionista.php', requestOptions);
                const data = await response.json();
                console.log(data);
                const requestOptionsIns = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        operacion: 'insert',
                        nombre: this.state.nombre,
                        correo: this.state.email,
                        telefono: this.state.telefono,
                        proyectos: this.state.proyectos,
                        proyectosC: this.state.proyectosC
                    })
                };
                const responseIns = await fetch('http://admin.garteel.com/php/DB/inversionistas.php', requestOptionsIns);
                const dataIns = await responseIns.json();
                console.log(dataIns);
                alert('Cuenta de Inversionista Creada');
                this.props.cerrarModal();
                this.props.actualizarTabla();
            }catch (error) {
                switch (error.code) {
                    case 'auth/internal-error':
                        this.setState({
                            error: 'Error interno' 
                        })
                        break;
                    case 'auth/email-already-in-use':
                        this.setState({
                            error: 'Este correo electrónico ya está registrado' 
                        })
                        break;
                    case 'auth/weak-password':
                        break;
                    default:
                        this.setState({
                            error: error.code
                        })
                        break;
                }
            }
    }

    hideModal = () => {
        this.props.cerrarModal();
        this.setState({
            error: null,
            nombre: '',
            email: '',
            telefono: ''
        })
    }

    render() { 
        return ( 
            <>
                <Modal show={this.props.show} onHide={this.hideModal} centered>
                    <form onSubmit={this.submit} >
                    <Modal.Header className='d-flex justify-content-center'>
                        <h5>Registrar Cliente</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <label>Nombre Completo</label>
                                    <input type="text" name='nombre' className='form-control mb-2' onChange={this.onChangeValue} required/>
                            </div>
                            <div className="col-6">
                                <label>Correo Electrónico</label>
                                    <input type='email' name='email' className="form-control mb-2" onChange={this.onChangeValue} required/>
                            </div>
                            <div className="col-6">
                                <label>Número Telefónico</label>
                                    <input type='tel' name='telefono' className="form-control mb-2" onChange={this.onChangeValue} required/>
                            </div>
                            <div className="col-12 text-center">
                                <label>Acceso a proyectos de inversión</label>
                                <hr className='m-1 p-0'/>
                            </div>
                            <div className="col-5">
                                <label>Proyecto</label>
                                <select className='form-control' name='proyecto' value={this.state.proyecto} onChange={this.onChangeValue}>
                                    <option value="">Seleccionar</option>
                                    {this.state.dataGet.map(e =>
                                        <option key={e.idProyecto} value={e.idProyecto}>{e.nombre}</option>
                                        )}
                                </select>
                            </div>
                            <div className="col-4">
                                <label>Inversión</label>
                                <NumberFormat thousandSeparator={true} prefix={'$'} className='form-control' value={this.state.inversion} onValueChange={(values) => { const { value } = values; this.setState({ inversion: value }); }}/>
                            </div>
                            <div className="col-3 d-flex align-items-end">
                                <button className='btn btn-secondary w-100' onClick={this.agregarProyecto}>Agregar</button>
                            </div>
                            <div className="col-12">
                                <table className="table table-striped tablaAdministracion mt-3">
                                    <thead className='thead'>
                                        <tr>
                                            <th style={{ width: '50%' }}>
                                                Proyecto
                                            </th>
                                            <th style={{ width: '20%' }}>
                                                Inversión
                                            </th>
                                            <th className='text-center' style={{ width: '30%' }}>
                                                Acciones
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.proyectos[0] ?
                                        this.state.proyectos.map((e, index) =>
                                            <tr key={index}>
                                                <td className='align-middle'>
                                                    {e.nombre}
                                                </td>
                                                <td className='align-middle'>
                                                    ${this.numberWithCommas(e.inversion)}
                                                </td>
                                                <td>
                                                    <button className='btn btn-sm btn-secondary' style={{ width: '48%' }} type='button' name={e.idProyecto} value={e.inversion} onClick={this.editarProyecto}>Editar</button>
                                                    <button className='btn btn-sm btn-danger ms-1' style={{ width: '48%' }} type='button' name={e.idProyecto} onClick={this.quitarProyecto}>Borrar</button>
                                                </td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan='3' className='text-center'>Agregue elementos a la lista</td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 text-center">
                                <label>Acceso a proyectos de construcción</label>
                                <hr className='m-1 p-0'/>
                            </div>
                            <div className="col-9">
                                <label>Proyecto</label>
                                <select className='form-control' name='proyectoC' value={this.state.proyectoC} onChange={this.onChangeValue}>
                                    <option value="">Seleccionar</option>
                                    {this.state.proyectosConstruccion.map(e =>
                                        <option key={e.idProyectoConstruccion} value={e.idProyectoConstruccion}>{e.nombre}</option>
                                        )}
                                </select>
                            </div>
                            <div className="col-3 d-flex align-items-end">
                                <button className='btn btn-secondary w-100' onClick={this.agregarProyectoC}>Agregar</button>
                            </div>
                            <div className="col-12">
                                <table className="table table-striped tablaAdministracion mt-3">
                                    <thead className='thead'>
                                        <tr>
                                            <th style={{ width: '50%' }}>
                                                Proyecto
                                            </th>
                                            <th className='text-center' style={{ width: '30%' }}>
                                                Acciones
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.proyectosC[0] ?
                                        this.state.proyectosC.map((e, index) =>
                                            <tr key={index}>
                                                <td className='align-middle'>
                                                    {e.nombre}
                                                </td>
                                                <td className='text-center'>
                                                    <button className='btn btn-sm btn-danger' style={{ width: 'fit-content' }} type='button' name={e.idProyectoConstruccion} onClick={this.quitarProyectoC}>Borrar</button>
                                                </td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan='3' className='text-center'>Agregue elementos a la lista</td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {this.state.error && 
                            <p className='error mt-2 mb-0 w-100 bg-danger text-center py-2' style={{ color: 'white', borderRadius: '5px' }}>{this.state.error}</p>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <input className="btn btn-success" type='submit' value='Registrar' />
                    </Modal.Footer>
                    </form>
                </Modal>
            </>
         );
    }
}
 
export default RegistrarInversionista;