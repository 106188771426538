import React, {useContext, useState} from 'react'
import {MyContext} from '../contexts/MyContext'

function Login(){

    const {loginUser,isLoggedIn} = useContext(MyContext);


    const initialState = {
        userInfo:{
            email:'',
            password:'',
        },
        errorMsg:'',
        successMsg:'',
    }

    const [state,setState] = useState(initialState);

    // On change input value (email & password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }

    // On Submit Login From
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await loginUser(state.userInfo);
        if(data.success && data.token){
            setState({
                ...initialState,
            });
            localStorage.setItem('loginToken', data.token);
            await isLoggedIn();
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    // Show Message on Error or Success
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if(state.successMsg){
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }


        return(
            <div className="login">
            <div className="row justify-content-center">
                <div className="col-9 col-xl-4 col-lg-5 col-md-6 col-sm-7 mt-5">
                    <div className="card">
                        <div className="card-header text-center">
                            <h1>Inicio de Sesión</h1>
                        </div>
                        <div className="card-body">
                            <form onSubmit={submitForm} noValidate>
                                <label>Email</label>
                                    <input 
                                        className="form-control"
                                        name="email" 
                                        type="email" 
                                        required 
                                        placeholder="Ingrese su correo electrónico" 
                                        value={state.userInfo.email} 
                                        onChange={onChangeValue} 
                                    />
                                <label className="mt-3">Contraseña</label>
                                    <input 
                                    className="form-control"
                                    name="password" 
                                    type="password" 
                                    required 
                                    placeholder="Ingrese su contraseña" 
                                    value={state.userInfo.password} 
                                    onChange={onChangeValue} 
                                />
                                <button type="submit" className="form-control mt-3 btn btn-success">Ingresar</button>
                            </form>
                        </div>
                        <div className="card-footer">
                            {errorMsg}
                            {successMsg}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )

}

export default Login;