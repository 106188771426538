import React from 'react';
import { Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/editortexto.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

class EditarPropiedad extends React.Component {
    constructor() {
        super();
        this.imgPrincipal = React.createRef()
        this.imgAdicionales = React.createRef()
        const html = '';
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.state = {
              editorState,
              casaGarteel: '',
              nombre: '',
              precio:'',
              estatus: '',
              tipoPropiedad: '',
              recamaras: '',
              banosCompletos: '',
              banosMedios: '',
              garage: '',
              metrosCuadrados: '',
              metrosConstruidos: '',
              ubicacion: '',
              descripcion: '',
              linkMapa: '',
              urlmgP: '',
              urlsImgA: [],
              amenidades : [],
              hideInputImgP: true,
              hideInputImgA: true,
              hideBtnImgP : false,
              hideBtnImgA : false,
              imgP: '',
              imgA: []
            };
          }else{
            this.state = {
                casaGarteel: '',
                nombre: '',
                precio:'',
                estatus: '',
                tipoPropiedad: '',
                recamaras: '',
                banosCompletos: '',
                banosMedios: '',
                garage: '',
                metrosCuadrados: '',
                metrosConstruidos: '',
                ubicacion: '',
                descripcion: '',
                linkMapa: '',
                urlmgP: '',
                urlsImgA: [],
                amenidades: [],
                hideInputImgP: true,
                hideInputImgA: true,
                hideBtnImgP : false,
                hideBtnImgA : false,
                imgP: '',
                imgA: []
            }
          }
    }

    componentDidMount = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select'  })
          };
        const response = await fetch('http://admin.garteel.com/php/DB/amenidades.php', requestOptions);
        const data = await response.json();
        this.setState({
            amenidades: data
        })
    }

    select = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectWhere', idPropiedad: this.props.idPropiedad  })
          };
        const response = await fetch('http://admin.garteel.com/php/DB/propiedades.php', requestOptions);
        const data = await response.json();
        this.setState({
            nombre: data[0].nombre,
            precio: data[0].precio,
            estatus: data[0].estatus,
            tipoPropiedad: data[0].tipoPropiedad,
            recamaras: data[0].recamaras,
            banosCompletos: data[0].banosCompletos,
            banosMedios: data[0].banosMedios,
            garage: data[0].garage,
            metrosCuadrados: data[0].metrosCuadrados,
            metrosConstruidos: data[0].metrosConstruidos,
            casaGarteel: data[0].casaGarteel,
            ubicacion: data[0].ubicacion,
            descripcion: data[0].descripcion,
            linkMapa: data[0].linkMapa,
            urlmgP: data.urlImgP,
            urlsImgA: data.urlsImgA
        })
        let amenidades = this.state.amenidades;
        let amenidadesGet = data.amenidades;
        amenidades.forEach(amenidad => {
        amenidadesGet.forEach(amenidadGet => {
            if(amenidad.idAmenidad === amenidadGet.ap_idAmenidad){
            amenidad.isChecked = amenidadGet.isChecked
            }
        })
        })
        this.setState({amenidades:amenidades})
        const contentBlock =  htmlToDraft(data[0].descripcion);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
            editorState: editorState
        })
    }

    registrar = async (e) => {
        e.preventDefault();
        console.log(this.state);
        let nombreLink = this.state.nombre.replace(/ /g, '-');
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              operacion: 'update', 
              idPropiedad: this.props.idPropiedad,
              nombre: this.state.nombre, 
              nombreLink: nombreLink, 
              precio: this.state.precio, 
              estatus: this.state.estatus, 
              tipoPropiedad: this.state.tipoPropiedad, 
              recamaras: this.state.recamaras, 
              banosCompletos: this.state.banosCompletos, 
              banosMedios: this.state.banosMedios, 
              garage: this.state.garage, 
              metrosCuadrados: this.state.metrosCuadrados, 
              metrosConstruidos: this.state.metrosConstruidos, 
              ubicacion: this.state.ubicacion, 
              descripcion: this.state.descripcion, 
              linkMapa: this.state.linkMapa, 
              casaGarteel: this.state.casaGarteel, 
              amenidades: this.state.amenidades,
              imgP: this.state.imgP,
              imgA: this.state.imgA
            })
          };
        const response = await fetch('http://admin.garteel.com/php/DB/propiedades.php', requestOptions);
        const data = await response.json();
        console.log(data);
        const requestOptionsArchivos = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              operacion: 'update', 
              imgP: this.state.imgP,
              imgA: this.state.imgA
            })
          };
        const responseArchivos = await fetch('https://www.garteel.com/php/DB/borrarArchivo.php', requestOptionsArchivos);
        const dataArchivos = await responseArchivos.json();
        console.log(dataArchivos);
        let idPropiedad = this.props.idPropiedad;
        this.props.cerrarModal();
        const formData = new FormData()
        formData.append('imgPrincipal',this.imgPrincipal.current.files[0]);
        formData.append('idPropiedad', idPropiedad);
        for (var i = 0; i < this.imgAdicionales.current.files.length; i++) {
            let nombre = 'imgAdicional' + i;
            formData.append(nombre,this.imgAdicionales.current.files[i]);
         }
        var xhr = new XMLHttpRequest();
        xhr.open('POST','https://www.garteel.com/php/DB/propiedadesimg.php', true);
        xhr.onload = async function () {
            var dataImg = JSON.parse(this.responseText);
            console.log(dataImg);
            if(dataImg.imgPrincipal){
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                      operacion: 'insert', nombre: dataImg.imgPrincipal, img_idPropiedad: idPropiedad, tipo: 'Principal'
                    })
                  };
                const response = await fetch('http://admin.garteel.com/php/DB/imagenespropiedades.php', requestOptions);
                const data = await response.json();
                console.log(data);
            }else{
                console.log('no hay imagen principal');
            }
            if(dataImg.imgAdicional){
                dataImg.imgAdicional.forEach(async (element) => {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                        operacion: 'insert', nombre: element, img_idPropiedad: idPropiedad, tipo: 'Adicional'
                        })
                    };
                    const response = await fetch('http://admin.garteel.com/php/DB/imagenespropiedades.php', requestOptions);
                    const data = await response.json();
                    console.log(data);
                    }
                )
            }else{
                console.log('no hay imagenes adicionales');
            }
        }
        xhr.send(formData);
        alert('Propiedad Actualizada');
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState,
          descripcion: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        });
      };

    onChangeImgP = () => {
        this.setState({
            urlmgP : URL.createObjectURL(this.imgPrincipal.current.files[0])
        })
    }

    onChangeImgA = () => {
        const newURLS = [];
        for (var i = 0; i < this.imgAdicionales.current.files.length; i++) {
            newURLS.push(URL.createObjectURL(this.imgAdicionales.current.files[i]));
         }
        this.setState({
            urlsImgA: newURLS
        })
    }

    onChangeValueAmenidad = (e) => {
        let amenidades = this.state.amenidades
        amenidades.forEach(amenidad => {
            if(amenidad.idAmenidad === e.target.value){
                amenidad.isChecked = e.target.checked
            }
        })
        this.setState({amenidades:amenidades})
    }

    cambiarImgP = () => {
        this.setState({
                urlmgP : '',
            hideInputImgP : false,
            hideBtnImgP : true
        })
        let url = this.state.urlmgP;
        let nombre = url.split('/').pop();
        this.setState({
            imgP: nombre
        });
    }

    cambiarImgA = async () => {
        this.setState({
                urlsImgA : [],
            hideInputImgA : false,
            hideBtnImgA : true
        })
        let urls = this.state.urlsImgA;
        let nombres = [];
        for (var i = 0; i < urls.length; i++) {
            nombres[i] = urls[i][0].split('/').pop();
         }
        await this.setState({
            imgA : nombres
        })
        console.log(this.state.imgA);
    }

    render() { 
        const { editorState } = this.state;
        return ( 
            <>
                <Modal show={this.props.show} onHide={this.props.cerrarModal} size='xl' onShow={this.select}>
                        <form action="" onSubmit={this.registrar}>
                            <Modal.Header className='d-flex justify-content-center'>
                                <h5>Editar Propiedad en Venta</h5>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-8">
                                        <label className='labelRegistro mt-3'>Nombre (Título)</label>
                                            <input type="text" className='form-control' name='nombre' value={this.state.nombre} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-2">
                                        <label className='labelRegistro mt-3'>Precio de Venta</label>
                                            <NumberFormat thousandSeparator={true} prefix={'$'} className='form-control' value={this.state.precio} required onValueChange={(values) => { const { value } = values; this.setState({ precio: value }); }}/>
                                    </div>
                                    <div className="col-2">
                                        <label className='labelRegistro mt-3'>Estatus</label>
                                            <select name="estatus" className='form-control' value={this.state.estatus} required onChange={this.onChangeValue}>
                                                <option value="">Seleccionar</option>
                                                <option value="Entrega Inmediata">Entrega Inmediata</option>
                                                <option value="Para Apartar">Para Apartar</option>
                                                </select>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>Tipo de Propiedad</label>
                                            <select name="tipoPropiedad" className='form-control' value={this.state.tipoPropiedad} required onChange={this.onChangeValue}>
                                                <option value="">Seleccionar</option>
                                                <option value="Casa">Casa</option>
                                                <option value="Comercial">Comercial</option>
                                                <option value="Departamento">Departamento</option>
                                                <option value="Industrial">Industrial</option>
                                                <option value="Terreno">Terreno</option>
                                            </select>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>Número de Recámaras</label>
                                            <input type="number" step='1' className='form-control' name='recamaras' value={this.state.recamaras} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>Número de Baños Completos</label>
                                            <input type="number" step='1' className='form-control' name='banosCompletos' value={this.state.banosCompletos} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>Número de Baños Medios</label>
                                            <input type="number" step='1' className='form-control' name='banosMedios' value={this.state.banosMedios} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>Espacios de Estacionamiento</label>
                                            <input type="number" step='1' className='form-control' name='garage' value={this.state.garage} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>Metros Cuadrados</label>
                                            <input type="number" step='1' className='form-control' name='metrosCuadrados' value={this.state.metrosCuadrados} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>Metros Construidos</label>
                                            <input type="number" step='1' className='form-control' name='metrosConstruidos' value={this.state.metrosConstruidos} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>¿Es Calidad Garteel?</label>
                                            <select name="casaGarteel" className='form-control' value={this.state.casaGarteel} required onChange={this.onChangeValue}>
                                                <option value="">Seleccionar</option>
                                                <option value="si">Si</option>
                                                <option value="no">No</option>
                                            </select>
                                    </div>
                                    <div className="col-12">
                                        <label className='labelRegistro mt-3'>Ubicación (Dirección)</label>
                                            <input type="text" className='form-control' name='ubicacion' value={this.state.ubicacion} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-12">
                                        <label className='labelRegistro mt-3'>Descripción</label>
                                        <Editor
                                            toolbarHidden
                                            editorState={editorState}
                                            wrapperClassName="editor"
                                            toolbarClassName='editorToolBar'
                                            onEditorStateChange={this.onEditorStateChange}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <label className='labelRegistro mt-3'>HTML de Mapa</label>
                                            <input type="text" className='form-control' name='linkMapa' value={this.state.linkMapa} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-12">
                                        <label className='labelRegistro mt-3'>Imagen Principal</label>
                                            <input type="file" className='form-control' ref={this.imgPrincipal} accept="image/png, image/jpg, image/jpeg" onChange={this.onChangeImgP} hidden={this.state.hideInputImgP}/>
                                            <div className="text-center">
                                                <img src={this.state.urlmgP} alt="" style={{ maxHeight: '500px' }} className='my-3'/>
                                            </div>
                                            <button type='button' className='btn form-control btn-danger' onClick={this.cambiarImgP} hidden={this.state.hideBtnImgP}>{ this.state.urlmgP ? 'Quitar' : 'Agregar' }</button>
                                    </div>
                                    <div className="col-12">
                                        <label className='labelRegistro mt-3'>Imágenes Adicionales</label>
                                            <input type="file" className='form-control' ref={this.imgAdicionales} accept="image/png, image/jpg, image/jpeg" onChange={this.onChangeImgA} multiple hidden={this.state.hideInputImgA}/>
                                            <div className="text-center">
                                                {this.state.urlsImgA && this.state.urlsImgA.map((imageSrc, index) => <img key={index} src={imageSrc} alt="" width="33%" className='mt-3 mx-2'/>) }
                                            </div>
                                            <button type='button' className='btn form-control btn-danger mt-2' onClick={this.cambiarImgA} hidden={this.state.hideBtnImgA}>{ this.state.urlsImgA[0] ? 'Quitar (Todas)' : 'Agregar' }</button>
                                    </div>
                                    <div className="col-12">
                                        <label className="labelRegistro mt-3">Amenidades</label>
                                            <div className="form-control">
                                                {this.state.amenidades.map(e =>
                                                    <li style={{listStyle: 'none'}} key={e.idAmenidad}><input type="checkbox" value={e.idAmenidad} checked={e.isChecked} onChange={this.onChangeValueAmenidad}/> {e.nombre}</li>
                                                    )}
                                            </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className='btn-sm btn btn-secondary' type='button' onClick={this.props.cerrarModal}>Cancelar</button>
                                <button className='btn-sm btn btn-success' type='submit'>Registrar</button>
                            </Modal.Footer>
                        </form>
                    </Modal>
            </>
         );
    }
}
 
export default EditarPropiedad;