import React from 'react';
import Navbar from '../NavBar';
import { MyContext } from '../../contexts/MyContext';
import Login from '../Login';
import RegistrarProyecto from './RegistrarProyecto';
import TablaProyectos from './TablaProyectos';

class Proyectos extends React.Component {
    static contextType = MyContext;

    constructor(){
        super();
        this.state = {
            data: [],
            showModalReg: false,
        }
    }

    componentDidMount = async () => {
        document.title = 'Proyectos - AdminGarteel';
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
          };
          const response = await fetch('http://admin.garteel.com/php/DB/proyectosconstruccion.php', requestOptions);
          const data = await response.json();
          this.setState({
              data: data
          })
    }

    cerrarModal = () => {
        this.setState({
            showModalReg: false
        });
    }

    abrirModal = () => {
        this.setState({
            showModalReg: true
        })
    }

    actualizar = () => {
        this.componentDidMount();
    }

    render() { 
        if(this.context.rootState.isAuth === true){
            return <>
                <Navbar/>
                    <div className="container-fluid">
                        <div className="row botoneraUsuarios bg-light">
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-outline-success my-2 btnUsuarios" onClick={this.abrirModal}>Agregar</button>
                            </div>    
                        </div>
                        
                    </div>
                    <RegistrarProyecto show={this.state.showModalReg} cerrarModal={this.cerrarModal} actualizar={this.actualizar}/>
                    <TablaProyectos stateData={this.state.data} actualizarTabla={this.actualizar} permisos={this.context.rootState.theUser.us_idTipoUsuario}/>
                </>;
            }else{
                return (
                    <Login/>
                )
            }
    }
}
 
export default Proyectos;