import React from 'react';
import Navbar from '../NavBar';
import {MyContext} from '../../contexts/MyContext';
import Login from '../Login';
import RegistrarInversionista from './RegistrarInversionista';
import TablaInversionistas from './TablaInversionistas';

class Inversionistas extends React.Component {

    static contextType = MyContext;

    constructor(){
        super();
        this.state = {
            showModalRegistrar: false,
            data: []
        }
    }

    componentDidMount = async () => {
        document.title = 'Inversionistas - AdminGarteel';
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
        };
        const response = await fetch('http://admin.garteel.com/php/DB/inversionistas.php', requestOptions);
        const data = await response.json();
        this.setState({
            data: data
        });
    }

    abrirModal = () => {
        this.setState({
            showModalRegistrar: true
        })
    }

    cerrarModal = () => {
        this.setState({
            showModalRegistrar: false
        })
    }

    actualizarTabla = () => {
        this.componentDidMount();
    }

    render() { 
        if(this.context.rootState.isAuth === true){
            return <>
                <Navbar/>
                <div className="container-fluid">
                    <div className="row botoneraUsuarios bg-light">
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-outline-success my-2 btnUsuarios" onClick={this.abrirModal} disabled={this.context.rootState.theUser.us_idTipoUsuario !== '1'}>Agregar</button>
                        </div>    
                    </div>
                </div>
                <RegistrarInversionista show={this.state.showModalRegistrar} cerrarModal={this.cerrarModal} actualizarTabla={this.actualizarTabla}/>
                <TablaInversionistas stateData={this.state.data} actualizarTabla={this.actualizarTabla} permisos={this.context.rootState.theUser.us_idTipoUsuario}/>
                </>;
            }else{
                return (
                    <Login/>
                )
            }
    }
}
 
export default Inversionistas;