import React, {useContext,useState} from 'react'
import {MyContext} from '../../../contexts/MyContext'
import {Modal, Button} from 'react-bootstrap'

function Register(props){
    const {registerUser} = useContext(MyContext);
    const initialState = {
        userInfo:{
            name:'',
            lastname:'',
            email:'',
            userType:'',
            password:'',
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);

    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await registerUser(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
            props.actualizarTabla();
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }

    const onChangeValueUserType = (e) => {
        console.log(e.target.value);
        if (e.target.value === '2') {
            setState({
                ...state,
                hidePermisos: false,
                userInfo:{
                    ...state.userInfo,
                    [e.target.name]:e.target.value
                }
            });
        }else{
            setState({
                ...state,
                hidePermisos: true,
                userInfo:{
                    ...state.userInfo,
                    [e.target.name]:e.target.value
                }
            });
        }
    }

    const cerrarModal = () => {
        props.cerrarModal();
        setState({
          ...state,
          errorMsg: '',
          successMsg: ''
        })
        setState({
            ...state,
            userInfo:{
                ...state,
                name:'',
                lastname:'',
                email:'',
                userType:'',
                password:'',
            }
        });
      }
    
    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if(state.successMsg){
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }

    return(
        <Modal show={props.show} onHide={cerrarModal}>
            <form onSubmit={submitForm} noValidate>
            <Modal.Header>
              <Modal.Title>Registrar Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-control">
                    <label>Nombre(s)</label>
                        <input name="name" className="form-control" required type="text" value={state.userInfo.name} onChange={onChangeValue} placeholder="Ingrese su nombre"/>
                    <label>Apellido(s)</label>
                        <input name="lastname" className="form-control" required type="text" value={state.userInfo.lastname} onChange={onChangeValue} placeholder="Ingrese sus apellidos"/>
                    <label>Email</label>
                        <input name="email" className="form-control" required type="email" value={state.userInfo.email} onChange={onChangeValue} placeholder="Ingrese su correo electrónico"/>
                    <label>Contraseña</label>
                        <input name="password" className="form-control" required type="password" value={state.userInfo.password} onChange={onChangeValue} placeholder="Ingrese su contraseña"/>
                    <label>Tipo de usuario</label>
                        <select name="userType" className="form-control" required value={state.userInfo.userType} onChange={onChangeValueUserType}>
                            <option value="">Seleccionar</option>
                            <option value="1">Administrador</option>
                            <option value="2">Empleado</option>
                        </select>
                </div>
                {errorMsg}
                {successMsg}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={cerrarModal}>
                Cancelar
              </Button>
              <Button type="submit" variant="success">
                Registrar Usuario
              </Button>
          </Modal.Footer>
          </form>
        </Modal>
    );
}

export default Register;