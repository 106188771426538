import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class EditarProyecto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: ''
        }
    }

    select = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectWhere', idProyectoConstruccion: this.props.idProyectoConstruccion })
          };
          const response = await fetch('http://admin.garteel.com/php/DB/proyectosconstruccion.php', requestOptions);
          const data = await response.json();
          if (data.error === 0) {
            this.setState({
                nombre: data.data[0].nombre
            })
          }else{
            alert('Error al obtener proyecto de construcción.');
            console.log(data);
          }
    }

    editar = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'update', idProyectoConstruccion: this.props.idProyectoConstruccion, nombre: this.state.nombre })
          };
          const response = await fetch('http://admin.garteel.com/php/DB/proyectosconstruccion.php', requestOptions);
          const data = await response.json();
          if (data.error === 0) {
            this.props.cerrarModal();
            this.props.actualizarTabla();
          }else{
            alert('Error al obtener proyecto de construcción.');
            console.log(data);
          }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    
    render() { 
        return ( 
            <>
                <Modal show={this.props.show} onHide={this.props.cerrarModal} onShow={this.select} centered>
                    <form action="" onSubmit={this.editar}>
                    <Modal.Header>
                        <h4>Editar Proyecto de Construcción</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <label htmlFor="nombre">Nombre</label>
                            <input type="text" name="nombre" id="nombre" className='form-control' value={this.state.nombre} onChange={this.onChangeValue} required/>
                    </Modal.Body>
                    <Modal.Footer>                        
                        <button type='button' className='btn btn-secondary' onClick={() => this.props.cerrarModal()}>Cancelar</button>
                        <button type='submit' className='btn btn-success'>Editar</button>
                    </Modal.Footer>
                    </form>
                </Modal>
            </>
         );
    }
}
 
export default EditarProyecto;