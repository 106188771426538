import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class RegistrarProyecto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: ''
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    registrar = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'insert', nombre: this.state.nombre })
          };
          const response = await fetch('http://admin.garteel.com/php/DB/proyectosconstruccion.php', requestOptions);
          const data = await response.json();
          if (data.error === 0) {
            this.props.cerrarModal();
            this.props.actualizar();
            this.setState({
                nombre: ''
            })
          }else{
            alert('Error al agregar usuario.');
            console.log(data);
          }
    }
    
    render() { 
        return (
            <>
                <Modal show={this.props.show} onHide={this.props.cerrarModal} centered>
                    <form action="" onSubmit={this.registrar}>
                    <Modal.Header>
                        <h4>Registrar Proyecto de Construcción</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <label htmlFor="nombre">Nombre</label>
                            <input type="text" name="nombre" id="nombre" className='form-control' value={this.state.nombre} onChange={this.onChangeValue} required/>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='button' className='btn btn-secondary' onClick={() => this.props.cerrarModal()}>Cancelar</button>
                        <button type='submit' className='btn btn-success'>Registrar</button>
                    </Modal.Footer>
                    </form>
                </Modal>
            </>
         );
    }
}
 
export default RegistrarProyecto;