import React, { Component } from 'react';
import EditarProyecto from './EditarProyecto';

class TablaProyectos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            idProyectoConstruccion: '',
            disableBtn: false
        }
    }

    abrirModal = (e) => {
        this.setState({
            idProyectoConstruccion: e.target.id,
            showModal: true
        })
    }

    cerrarModal = () => {
        this.setState({
            idProyectoConstruccion: '',
            showModal: false
        })
    }

    borrar = async (e) => {
        if (window.confirm('¿Borrar proyecto de construcción?')) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'delete', idProyectoConstruccion: e.target.id })
              };
              const response = await fetch('http://admin.garteel.com/php/DB/proyectosconstruccion.php', requestOptions);
              const data = await response.json();
              if (data.error === 0) {
                this.cerrarModal();
                this.props.actualizarTabla();
              }else{
                alert('Error al obtener proyecto de construcción.');
                console.log(data);
              }
        }
    }
    
    render() { 
        return ( 
            <>
                <div className="container">
                    <table className="table table-striped mt-3 tablaAdministracion">
                        <thead className="thead">
                            <tr>
                                <th>Nombre</th>
                                <th className="text-center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.stateData.map(e =>
                                <tr key={e.idProyectoConstruccion}>
                                    <td>{e.nombre}</td>
                                    <td className="text-center" style={{width: '15%'}}>
                                        <button  id={e.idProyectoConstruccion} className="btn btn-sm btn-secondary btnEdit mx-1" variant="primary" onClick={this.abrirModal} disabled={this.state.disableBtn}>
                    
                                        </button>
                                        <button id={e.idProyectoConstruccion} className="btn btnDelete btn-danger mx-1" onClick={this.borrar} disabled={this.state.disableBtn}>

                                        </button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <EditarProyecto show={this.state.showModal} cerrarModal={this.cerrarModal} idProyectoConstruccion={this.state.idProyectoConstruccion} actualizarTabla={this.props.actualizarTabla}/>
            </>
         );
    }
}
 
export default TablaProyectos;