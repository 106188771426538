import React from 'react';
import { Card } from 'react-bootstrap';
import TarjetaFase from './TarjetaFase';
import RegistrarFase from './RegistrarFase';

class AvancesProyecto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: false,
            fases: [],
            showModalRegistrar: false
        }
    }

    componentDidMount = () => {
        this.select();
    }

    select = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectFases', idProyecto: this.props.idProyecto  })
          };
        const response = await fetch('http://admin.garteel.com/php/DB/proyectos.php', requestOptions);
        const data = await response.json();
        this.setState({
            fases: data
        })
    }

    guardar = () => {
        console.log(this.state.fases);
    }

    toggleHidden = () => {
        this.setState({
            hidden: !this.state.hidden
        })
    }

    actualizar = () => {
        this.componentDidMount();
    }

    openModalRegistrar = () => {
        this.setState({
            showModalRegistrar: true
        })
    }

    closeModalRegistrar = () => {
        this.setState({
            showModalRegistrar: false
        })
    }

    render() { 
        return ( 
            <>
            <Card className='mt-4'>
                    <form action="">
                        <Card.Header className='d-flex align-items-center'>
                            <div className="row w-100">
                                <div className="col-11 d-flex align-items-center">
                                    <h5 className='m-0'>Editar Fases</h5>
                                </div>
                                <div className="col-1 text-end">
                                    {
                                        this.state.hidden ?
                                            <button className='btn h-100' type='button' onClick={this.toggleHidden}>
                                            <i className="bi bi-caret-down-fill"></i>
                                            </button>
                                        :
                                            <button className='btn h-100' type='button' onClick={this.toggleHidden}>
                                            <i className="bi bi-caret-up-fill"></i>
                                            </button>
                                    }
                                </div>
                            </div>
                        </Card.Header>
                        <div hidden={this.state.hidden}>
                        <Card.Body>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button type='button' className='btn btn-success' onClick={this.openModalRegistrar}>Agregar</button>
                                </div>
                            </div>
                            <div className='row'>
                                {
                                    this.state.fases.map((e, index) =>
                                        <TarjetaFase key={index} data={e} actualizar={this.actualizar}/>
                                    )
                                }
                            </div>
                        </Card.Body>
                        </div>
                    </form>
                </Card>
                <RegistrarFase show={this.state.showModalRegistrar} closeModalRegistrar={this.closeModalRegistrar} idProyecto={this.props.idProyecto} actualizar={this.componentDidMount}/>
            </>
         );
    }
}
 
export default AvancesProyecto;