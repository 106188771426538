import React from 'react';
import { Card } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../css/editortexto.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class EditarProyecto extends React.Component {
    constructor() {
        super();
        this.imgPrincipal = React.createRef()
        this.imgAdicionales = React.createRef()
        this.documentos = React.createRef()
        const html = '';
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.state = {
              hidden: true,
              editorState,
              nombre: '',
              ubicacion: '',
              dineroARecaudar:'',
              dineroRecaudado: '',
              tirGarantizado: '',
              tirEsperado: '',
              minInversion: '',
              plazo: '',
              fechaLimiteInversion: '',
              descripcion: '',
              instrumento: '',
              pagoRendimientos: '',
              respaldo: '',
              linkMapa: '',
              urlmgP: '',
              urlsImgA: [],
              documentos: [],
              representantes: [],
              rep_rol: '',
              rep_nombre: '',
              hideInputImgP: true,
              hideInputImgA: true,
              hideInputDocs: true,
              hideBtnImgP : false,
              hideBtnImgA : false,
              hideBtnDocs : false,
              imgP: '',
              imgA: [],
              docs: [],
            };
          }else{
            this.state = {
                hidden: true,
                nombre: '',
                ubicacion: '',
                dineroARecaudar:'',
                dineroRecaudado: '',
                tirGarantizado: '',
                tirEsperado: '',
                minInversion: '',
                plazo: '',
                fechaLimiteInversion: '',
                descripcion: '',
                instrumento: '',
                pagoRendimientos: '',
                respaldo: '',
                linkMapa: '',
                urlmgP: '',
                urlsImgA: [],
                documentos: [],
                representantes: [],
                rep_rol: '',
                rep_nombre: '',
                hideInputImgP: true,
                hideInputImgA: true,
                hideInputDocs: true,
                hideBtnImgP : false,
                hideBtnImgA : false,
                hideBtnDocs : false,
                imgP: '',
                imgA: [],
                docs: []
            }
          }
    }

    componentDidMount = () => {
        this.select()
    }

    select = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectWhere', idProyecto: this.props.idProyecto  })
          };
        const response = await fetch('http://admin.garteel.com/php/DB/proyectos.php', requestOptions);
        const data = await response.json();
        this.setState({
            nombre: data[0].nombre,
            ubicacion: data[0].ubicacion,
            dineroARecaudar: data[0].dineroARecaudar,
            dineroRecaudado: data[0].dineroRecaudado,
            tirGarantizado: data[0].tirGarantizado,
            tirEsperado: data[0].tirEsperado,
            minInversion: data[0].minInversion,
            plazo: data[0].plazo,
            fechaLimiteInversion: data[0].fechaLimiteInversion,
            descripcion: data[0].descripcion,
            instrumento: data[0].instrumento,
            pagoRendimientos: data[0].pagoRendimientos,
            respaldo: data[0].respaldo,
            linkMapa: data[0].linkMapa,
            urlmgP: data.urlImgP,
            urlsImgA: data.urlsImgA,
            documentos: data.docs,
            representantes: data[0].responsables,
        })
        const contentBlock =  htmlToDraft(data[0].descripcion);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
            editorState: editorState
        })
    }

    editar = async (e) => {
        e.preventDefault();
        let nombreLink = this.state.nombre.replace(/ /g, '-');
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              operacion: 'update', 
              idProyecto: this.props.idProyecto, 
              nombre: this.state.nombre, 
              nombreLink: nombreLink,
              ubicacion: this.state.ubicacion, 
              dineroARecaudar: this.state.dineroARecaudar, 
              dineroRecaudado: this.state.dineroRecaudado,
              tirGarantizado: this.state.tirGarantizado, 
              tirEsperado: this.state.tirEsperado, 
              minInversion: this.state.minInversion, 
              plazo: this.state.plazo, 
              fechaLimiteInversion: this.state.fechaLimiteInversion, 
              descripcion: this.state.descripcion, 
              instrumento: this.state.instrumento, 
              pagoRendimientos: this.state.pagoRendimientos, 
              respaldo: this.state.respaldo, 
              linkMapa: this.state.linkMapa, 
              representantes: this.state.representantes,
            })
          };
        const response = await fetch('http://admin.garteel.com/php/DB/proyectos.php', requestOptions);
        const data = await response.json();
        console.log(data);
        const requestOptionsArchivos = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              operacion: 'updateProyectos', 
              imgP: this.state.imgP,
              imgA: this.state.imgA,
              docs: this.state.docs
            })
          };
        const responseArchivos = await fetch('https://www.garteel.com/php/DB/borrarArchivo.php', requestOptionsArchivos);
        const dataArchivos = await responseArchivos.json();
        console.log(dataArchivos);
        let idProyecto = this.props.idProyecto;
        const formData = new FormData()
        formData.append('imgPrincipal',this.imgPrincipal.current.files[0]);
        formData.append('idProyecto', idProyecto);
        for (var i = 0; i < this.imgAdicionales.current.files.length; i++) {
            let nombre = 'imgAdicional' + i;
            formData.append(nombre,this.imgAdicionales.current.files[i]);
         }
        for (i = 0; i < this.documentos.current.files.length; i++) {
            let nombre = 'documento' + i;
            formData.append(nombre,this.documentos.current.files[i]);
         }
        var xhr = new XMLHttpRequest();
        xhr.open('POST','https://www.garteel.com/php/DB/proyectosimg.php', true);
        xhr.onload = async function () {
            var dataImg = JSON.parse(this.responseText);
            console.log(dataImg);
            if(dataImg.imgPrincipal){
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                      operacion: 'insert', nombre: dataImg.imgPrincipal, img_idProyecto: idProyecto, tipo: 'Principal'
                    })
                  };
                const response = await fetch('http://admin.garteel.com/php/DB/imagenesproyectos.php', requestOptions);
                const data = await response.json();
                console.log(data);
            }else{
                console.log('no hay imagen principal');
            }
            if(dataImg.imgAdicional){
                dataImg.imgAdicional.forEach(async (element) => {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                        operacion: 'insert', nombre: element, img_idProyecto: idProyecto, tipo: 'Adicional'
                        })
                    };
                    const response = await fetch('http://admin.garteel.com/php/DB/imagenesproyectos.php', requestOptions);
                    const data = await response.json();
                    console.log(data);
                    }
                )
            }else{
                console.log('no hay imagenes adicionales');
            }
            if(dataImg.documento){
                dataImg.documento.forEach(async (element) => {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                        operacion: 'insert', nombre: element, doc_idProyecto: idProyecto
                        })
                    };
                    const response = await fetch('http://admin.garteel.com/php/DB/documentosproyectos.php', requestOptions);
                    const data = await response.json();
                    console.log(data);
                    }
                )
            }else{
                console.log('no hay documentos');
            }
        }
        xhr.send(formData);
        alert('Proyecto Actualizado');
        this.setState({
            urlmgP : '',
            urlsImgA : []
        })
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState,
          descripcion: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        });
      };

    onChangeImgP = () => {
        this.setState({
            urlmgP : URL.createObjectURL(this.imgPrincipal.current.files[0])
        })
    }

    onChangeImgA = () => {
        const newURLS = [];
        for (var i = 0; i < this.imgAdicionales.current.files.length; i++) {
            newURLS.push(URL.createObjectURL(this.imgAdicionales.current.files[i]));
         }
        this.setState({
            urlsImgA: newURLS
        })
    }

    onChangeDocs = () => {
        let docs = [];
        for (var i = 0; i < this.documentos.current.files.length; i++) {
            docs.push(this.documentos.current.files[i].name);
         }
        this.setState({
            documentos: docs
        })
    }

    agregarRepresentante = () => {
        if (this.state.rep_nombre !== '' && this.state.rep_rol !== '') {
            let representante = {rol: this.state.rep_rol, nombre: this.state.rep_nombre};
            let representantes = this.state.representantes;
            representantes.push(representante);
            this.setState({
                representantes: representantes,
                rep_nombre: '',
                rep_rol: ''
            })
        }else{
            alert('Llena los campos primero')
        }
    }

    editarRepresentante = (e) => {
        let filteredArray = this.state.representantes.filter((representante) => representante.nombre !== e.target.name);
        this.setState({
            rep_rol: e.target.value,
            rep_nombre: e.target.name,
            representantes: filteredArray
        })
    } 

    quitarRepresentante = (e) => {
        let filteredArray = this.state.representantes.filter((representante) => representante.nombre !== e.target.name);
        this.setState({
            representantes: filteredArray
        })
    }

    cambiarImgP = () => {
        this.setState({
                urlmgP : '',
            hideInputImgP : false,
            hideBtnImgP : true
        })
        let url = this.state.urlmgP;
        let nombre = url.split('/').pop();
        this.setState({
            imgP: nombre
        });
    }

    cambiarImgA = async () => {
        this.setState({
                urlsImgA : [],
            hideInputImgA : false,
            hideBtnImgA : true
        })
        let urls = this.state.urlsImgA;
        let nombres = [];
        for (var i = 0; i < urls.length; i++) {
            nombres[i] = urls[i][0].split('/').pop();
         }
        await this.setState({
            imgA : nombres
        })
    }

    cambiarDocs = async () => {
        this.setState({
                documentos : [],
            hideInputDocs : false,
            hideBtnDocs : true
        })
        let docs = this.state.documentos;
        let nombres = [];
        console.log(docs);
        for (var i = 0; i < docs.length; i++) {
            nombres[i] = docs[i].ruta.split('/').pop();
         }
        await this.setState({
            docs : nombres
        })
    }

    toggleHidden = () => {
        this.setState({
            hidden: !this.state.hidden
        })
    }

    render() { 
        const { editorState } = this.state;
        return ( 
            <>
                <Card>
                    <form action="">
                        <Card.Header className='d-flex align-items-center'>
                            <div className="row w-100">
                                <div className="col-11 d-flex align-items-center">
                                    <h5 className='m-0'>Editar Proyecto</h5>
                                </div>
                                <div className="col-1 text-end">
                                    {
                                        this.state.hidden ?
                                            <button className='btn h-100' type='button' onClick={this.toggleHidden}>
                                            <i className="bi bi-caret-down-fill"></i>
                                            </button>
                                        :
                                            <button className='btn h-100' type='button' onClick={this.toggleHidden}>
                                            <i className="bi bi-caret-up-fill"></i>
                                            </button>
                                    }
                                </div>
                            </div>
                        </Card.Header>
                        <div hidden={this.state.hidden}>
                            <Card.Body>
                                <div className="row">
                                    <div className="col-12">
                                        <label className='labelRegistro'>Nombre (Título)</label>
                                            <input type="text" className='form-control' name='nombre' value={this.state.nombre} onChange={this.onChangeValue} required/>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>Dinero A Recaudar</label>
                                            <NumberFormat thousandSeparator={true} prefix={'$'} className='form-control' required value={this.state.dineroARecaudar} onValueChange={(values) => { const { value } = values; this.setState({ dineroARecaudar: value }); }}/>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>Dinero Recaudado</label>
                                            <NumberFormat thousandSeparator={true} prefix={'$'} className='form-control' required value={this.state.dineroRecaudado} onValueChange={(values) => { const { value } = values; this.setState({ dineroRecaudado: value }); }}/>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>TIR Garantizado (%)</label>
                                            <input type="number" step='1' className='form-control' max='100' name='tirGarantizado' value={this.state.tirGarantizado} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>TIR Esperado (%)</label>
                                            <input type="number" step='1' className='form-control' max='100' name='tirEsperado' value={this.state.tirEsperado} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>Inversión Mínima</label>
                                        <NumberFormat thousandSeparator={true} prefix={'$'} className='form-control' required value={this.state.minInversion} onValueChange={(values) => { const { value } = values; this.setState({ minInversion: value }); }}/>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>Plazo (Años)</label>
                                            <input type="number" step='1' className='form-control' name='plazo' value={this.state.plazo} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>Día Limite de Inversión</label>
                                            <input type="date" className='form-control' name='fechaLimiteInversion' value={this.state.fechaLimiteInversion} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-3">
                                        <label className='labelRegistro mt-3'>Instrumento</label>
                                            <input type="text" className='form-control' name='instrumento' value={this.state.instrumento} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-6">
                                        <label className='labelRegistro mt-3'>Pago de Rendimientos</label>
                                            <select className='form-control' name="pagoRendimientos" value={this.state.pagoRendimientos} required onChange={this.onChangeValue}>
                                                <option value="">Seleccionar</option>
                                                <option value="Mensual">Mensual</option>
                                                <option value="Trimestral">Trimestral</option>
                                                <option value="Cuatrimestral">Cuatrimestral</option>
                                                <option value="Semestral">Semestral</option>
                                                <option value="Anual">Anual</option>
                                            </select>
                                    </div>
                                    <div className="col-6">
                                        <label className='labelRegistro mt-3'>Respaldo</label>
                                            <input type="text" className='form-control' name='respaldo' value={this.state.respaldo} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-12">
                                        <label className='labelRegistro mt-3'>Ubicación (Dirección)</label>
                                            <input type="text" className='form-control' name='ubicacion' value={this.state.ubicacion} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-12">
                                        <label className='labelRegistro mt-3'>Descripción</label>
                                        <Editor
                                            toolbarHidden
                                            editorState={editorState}
                                            wrapperClassName="editor"
                                            toolbarClassName='editorToolBar'
                                            onEditorStateChange={this.onEditorStateChange}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <label className='labelRegistro mt-3'>HTML de Mapa</label>
                                            <input type="text" className='form-control' name='linkMapa' value={this.state.linkMapa} required onChange={this.onChangeValue}/>
                                    </div>
                                    <div className="col-12">
                                        <label className='labelRegistro mt-3'>Representantes</label>
                                        <div className="row">
                                            <div className="col-5">
                                                <input type="text" className='form-control' placeholder='Nombre' name='rep_nombre' value={this.state.rep_nombre} onChange={this.onChangeValue}/>
                                            </div>
                                            <div className="col-5">
                                                <input type="text" className='form-control' placeholder='Rol' name='rep_rol' value={this.state.rep_rol} onChange={this.onChangeValue}/>
                                            </div>
                                            <div className="col-2">
                                                <button className='btn btn-success w-100' type='button' onClick={this.agregarRepresentante}>Agregar</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-control mt-3 py-0" style={{ border: 'none' }}>
                                            {this.state.representantes[0] ?
                                                    this.state.representantes.map((e, index) =>
                                                        <div className="row py-2 my-1" key={index} style={{ borderRadius: '5px' , border: '1px solid #ced4da' }}>
                                                            <div className="col-8 d-flex align-items-center">
                                                                <p className='d-inline m-0'>{e.nombre}</p><p className='d-inline m-0' style={{ color: 'gray' }}> - {e.rol}</p>
                                                            </div>
                                                            <div className="col-2">
                                                                <button className='btn btn-sm btn-secondary w-100' type='button' name={e.nombre} value={e.rol} onClick={this.editarRepresentante}>Editar</button>
                                                            </div>
                                                            <div className="col-2">
                                                                <button className='btn btn-sm btn-danger w-100' type='button' name={e.nombre} onClick={this.quitarRepresentante}>Borrar</button>
                                                            </div>
                                                        </div>
                                                    )
                                                :
                                                    <div className="d-flex justify-content-center">
                                                        <p className='my-3 p-0' style={{ color: 'gray' }}>Agregue representantes a la lista</p>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label className='labelRegistro mt-3'>Imagen Principal</label>
                                            <input type="file" className='form-control' ref={this.imgPrincipal} accept="image/png, image/jpg, image/jpeg" required onChange={this.onChangeImgP} hidden={this.state.hideInputImgP}/>
                                            <div className="text-center">
                                                <img src={this.state.urlmgP} alt="" style={{ maxHeight: '400px' }} className='my-3'/>
                                            </div>
                                            <div className='text-center'>
                                                <button type='button' className='btn w-25 btn-danger' onClick={this.cambiarImgP} hidden={this.state.hideBtnImgP}>{ this.state.urlmgP ? 'Quitar' : 'Agregar' }</button>
                                            </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label className='labelRegistro'>Imágenes Adicionales</label>
                                            <input type="file" className='form-control' ref={this.imgAdicionales} accept="image/png, image/jpg, image/jpeg" required onChange={this.onChangeImgA} multiple hidden={this.state.hideInputImgA}/>
                                            <div className="text-center">
                                                {this.state.urlsImgA.map((imageSrc, index) => <img key={index} src={imageSrc} alt="" width="33%" className='mt-3 mx-2'/>) }
                                            </div>
                                            <div className='text-center'>
                                                <button type='button' className='btn w-25 btn-danger mt-2' onClick={this.cambiarImgA} hidden={this.state.hideBtnImgA}>{ this.state.urlsImgA[0] ? 'Quitar (Todas)' : 'Agregar' }</button>
                                            </div>
                                    </div>
                                    <div className="col-12">
                                        <label className='labelRegistro mt-3'>Documentos (PDF's)</label>
                                            <input type="file" className='form-control' ref={this.documentos} accept="application/pdf" required onChange={this.onChangeDocs} multiple hidden={this.state.hideInputDocs}/>
                                            <div className='row mt-2 d-flex justify-content-center'>
                                                {this.state.documentos.map((e, index) =>
                                                        {   return e.ruta ?
                                                            <div key={index} className="col-3 mx-1" style={{ border: '1px solid gray', borderRadius: '5px' }}>
                                                                <div className="row" style={{ borderBottom: '1px solid gray' }}>
                                                                    <div className="col-12 d-flex justify-content-center">
                                                                        <Document file={e.ruta}>
                                                                            <Page pageNumber={1} width={190}/>
                                                                        </Document>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 text-center">
                                                                        {e.nombre}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                                <li key={index} className='ms-2'>{e}</li>
                                                        }
                            
                                                )}
                                            </div>
                                            <div className='text-center'>
                                                <button type='button' className='btn w-25 btn-danger mt-2' onClick={this.cambiarDocs} hidden={this.state.hideBtnDocs}>{ this.state.documentos[0] ? 'Quitar (Todos)' : 'Agregar' }</button>
                                            </div>
                                    </div>
                                </div>
                            </Card.Body>
                            <Card.Footer className='text-end'>
                                <button className='btn btn-success' type='button' style={{ width: '150px' }} onClick={this.editar}>Editar</button>
                            </Card.Footer>
                        </div>
                    </form>
                </Card>
                <script src="/js/pdf.worker.min.js"></script>
                <script src="/js/pdf.min.js"></script>
            </>
         );
    }
}
 
export default EditarProyecto;