import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import '../../css/tarjetafase.css';
import EditarFase from './EditarFase';
import sinImagen from '../../img/sin-imagen.jpg';

class TarjetaFase extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            showModalEditar: false
        }
    }

    componentDidMount = () => {
        
    }

    openModalEditar = () => {
        this.setState({
            showModalEditar: true
        })
    }

    hideModalEditar = () => {
        this.setState({
            showModalEditar: false
        })
    }

    barraProgreso = () => {
        var porcentaje = this.props.data.porcentaje;
        var gradient = '';
        if (porcentaje < 50) {
            gradient = 'linear-gradient(to right, #212529 ' + porcentaje + '%, white ' + porcentaje + '%)';
        }else{
            gradient = 'linear-gradient(to right, #212529 ' + porcentaje + '%, white ' + (100 - Number(porcentaje)) + '%)';
        }
        return gradient
    }

    porcentaje = () => {
        var porcentaje = this.props.data.porcentaje;
        if (porcentaje < 10) {
            if (porcentaje < 3) {
                return '3%'
            }else{
                return porcentaje + '%'
            }
        }else{
            return (Number(porcentaje) - 7) + '%'   
        }
    }

    borrarFase = async (e) => {
        if (window.confirm("¿Eliminar Fase?")) {
            const requestOptionsImg = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    operacion: 'delete', 
                    idFase: e.target.id, 
                })
                };
            const responseImg = await fetch('https://inversionistas.garteel.com/php/borrarArchivos.php', requestOptionsImg);
            const dataImg = await responseImg.json();
            console.log(dataImg);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    operacion: 'delete', 
                    idFase: e.target.id, 
                })
                };
            const response = await fetch('http://admin.garteel.com/php/DB/fasesproyectos.php', requestOptions);
            const data = await response.json();
            console.log(data);
            this.props.actualizar();
        }
    }

    render() { 
        return (  
            <>
                <div className="col-4 tarjetaFase px-3 py-3 text-center">
                    <Card variant='outlined' style={{ borderRadius: '5px'}}>
                        <CardActionArea onClick={this.openModalEditar}>
                            <CardContent style={{ backgroundColor: '#212529' }}>
                                <Typography gutterBottom variant='h6' component="div" className='text-center' style={{ color: 'white', fontWeight: '200' }}>
                                    {this.props.data.titulo}
                                </Typography>
                            </CardContent>
                            {
                                this.props.data.imagen ?
                                    <CardMedia
                                        component="img"
                                        width="100"
                                        image={"https://inversionistas.garteel.com/img/" + this.props.data.imagen}
                                        alt="imgFase"
                                        className='imagenTarjeta'
                                    />
                                :
                                <CardMedia
                                        component="img"
                                        width="100"
                                        image={sinImagen}
                                        alt="imgFase"
                                        className='sinImagenTarjeta'
                                    />
                            }
                            <CardContent className='pt-0 px-0 pb-0'>
                                <div className="row p-0">
                                    <div className="col-12 p-0">
                                        <div className='' style={{ width: '100%', height: '20px', padding: '1px', background: this.barraProgreso() }}>
                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje() }}>
                                                {this.props.data.porcentaje + '%'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                    <div className="col-12">
                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>{this.props.data.descripcion}</p>
                                    </div>
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <button type='button' className='btn btn-danger mt-2' id={this.props.data.idFase} onClick={this.borrarFase}>Borrar</button>
                </div>
                <EditarFase showModalEditar={this.state.showModalEditar} hideModalEditar={this.hideModalEditar} data={this.props.data} actualizar={this.props.actualizar}/>
            </>
        );
    }
}
 
export default TarjetaFase;