import React from 'react';
import { Modal } from 'react-bootstrap';

class EditarFase extends React.Component {
    constructor(props) {
        super(props);
        this.img = React.createRef()
        this.state = {
            titulo: '',
            porcentaje: '',
            descripcion: '',
            hideInputImg:  true,
            hideBtnImg: false,
            urlImg: '',
            img: ''
        }        
    }

    hideModalEditar = () => {
        this.props.hideModalEditar();
    }

    select = () => {
        if (this.props.data.imagen !== '') {
            this.setState({
                titulo: this.props.data.titulo,
                porcentaje: this.props.data.porcentaje,
                descripcion: this.props.data.descripcion,
                urlImg: 'https://inversionistas.garteel.com/img/' + this.props.data.imagen
            })
        }else{
            this.setState({
                titulo: this.props.data.titulo,
                porcentaje: this.props.data.porcentaje,
                descripcion: this.props.data.descripcion,
            })
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambiarImg = () => {
        this.setState({
            urlImg : '',
            hideInputImg: false,
            hideBtnImg: true
        })
        let url = this.state.urlImg;
        let nombre = url.split('/').pop();
        this.setState({
            img: nombre
        });
    }

    onChangeImg = () => {
        this.setState({
            urlImg : URL.createObjectURL(this.img.current.files[0])
        })
    }

    actualizar = () => {
        this.props.actualizar();
    }

    guardar = async () => {
        const requestOptionsArchivos = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              operacion: 'update', 
              img: this.state.img,
            })
          };
        const actualizar = () => { this.props.actualizar() };
        var img = this.state.img;
        const responseArchivos = await fetch('https://inversionistas.garteel.com/php/borrarArchivos.php', requestOptionsArchivos);
        const dataArchivos = await responseArchivos.json();
        console.log(dataArchivos);
        var idFase = this.props.data.idFase;
        var datos = this.state;
        const formData = new FormData();
        formData.append('img',this.img.current.files[0]);
        formData.append('idFase', idFase);
        var xhr = new XMLHttpRequest();
        xhr.open('POST','https://inversionistas.garteel.com/php/imagenFase.php', true);
        xhr.onload = async function () {
            var dataImg = JSON.parse(this.responseText);
            console.log(dataImg);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    operacion: 'update', 
                    img: img,
                    imagen: dataImg.img, 
                    idFase: idFase, 
                    titulo: datos.titulo, 
                    porcentaje: datos.porcentaje, 
                    descripcion: datos.descripcion
                })
                };
            const response = await fetch('http://admin.garteel.com/php/DB/fasesproyectos.php', requestOptions);
            const data = await response.json();
            console.log(data);
            actualizar();
        }
        xhr.send(formData);
        this.hideModalEditar();
    }
    
    render() { 
        return ( 
            <>
                <Modal show={this.props.showModalEditar} onHide={this.hideModalEditar} size='lg' onShow={this.select}>
                    <Modal.Header>
                        <h3>Editar Fase</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-6">
                                <label className='labelRegistro'>Título</label>
                                    <input type="text" className='form-control' name='titulo' value={this.state.titulo} onChange={this.onChangeValue}/>
                            </div>
                            <div className="col-6">
                                <label className='labelRegistro'>Porcentaje</label>
                                    <select name="porcentaje" className='form-control' value={this.state.porcentaje} onChange={this.onChangeValue}>
                                        <option value="0">0%</option>
                                        <option value="10">10%</option>
                                        <option value="20">20%</option>
                                        <option value="30">30%</option>
                                        <option value="40">40%</option>
                                        <option value="50">50%</option>
                                        <option value="60">60%</option>
                                        <option value="70">70%</option>
                                        <option value="80">80%</option>
                                        <option value="90">90%</option>
                                        <option value="100">100%</option>
                                    </select>
                            </div>
                            <div className="col-12 mt-3">
                                <label className='labelRegistro'>Descripción</label>
                                    <textarea className='form-control' name="descripcion" cols="30" rows="5" value={this.state.descripcion} onChange={this.onChangeValue}/>
                            </div>
                            <div className="col-12 mt-3">
                                <label className='labelRegistro'>Imagen</label>
                                    <input type="file" className='form-control' ref={this.img} accept="image/png, image/jpg, image/jpeg" onChange={this.onChangeImg} hidden={this.state.hideInputImg}/>
                                    <div className="text-center">
                                        <img src={this.state.urlImg} alt="" style={{ maxHeight: '400px' }} className='my-3'/>
                                    </div>
                                    <div className='text-center'>
                                        <button type='button' className='btn w-25 btn-danger' onClick={this.cambiarImg} hidden={this.state.hideBtnImg}>{ this.state.urlImg ? 'Quitar' : 'Agregar' }</button>
                                    </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='button' className='btn btn-secondary' onClick={this.hideModalEditar}>Cancelar</button>
                        <button type='button' className='btn btn-success' onClick={this.guardar}>Guardar</button>
                    </Modal.Footer>
                </Modal>            
            </>
         );
    }
}
 
export default EditarFase;