import React from 'react';
import {MyContext} from '../../../contexts/MyContext';
import Navbar from '../../NavBar';
import Login from '../../Login';
import TablaUsuarios from './TablaUsuarios';
import Registrar from './Registrar'

class Usuarios extends React.Component {

    constructor(){
        super();
        this.state = {
            data: [],
            show: false,
            disableBtn: false
        }
    }

    static contextType = MyContext;

    async componentDidMount(){
        document.title = 'Usuarios - AdminGarteel';
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
        };
        const response = await fetch('http://admin.garteel.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        this.setState({
            data: data
        });
    }

    cerrarModal = () => this.setState({show: false});
    abrirModal = () => {
        this.setState({
            show: true
        })
    }

    actualizarTabla = () => {
        this.componentDidMount();
    }

    render() { 
        if(this.context.rootState.isAuth === true){
            return(
                <div>
                    <Navbar/>
                    <div className="container-fluid">
                        <div className="row botoneraUsuarios bg-light">
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-outline-success my-2 btnUsuarios" onClick={this.abrirModal} disabled={this.context.rootState.theUser.us_idTipoUsuario !== '1'}>Agregar</button>
                            </div>    
                        </div>                       
                        <Registrar show={this.state.show} cerrarModal={this.cerrarModal} actualizarTabla={this.actualizarTabla}/>
                        <TablaUsuarios stateData={this.state.data} actualizarTabla={this.actualizarTabla} permisos={this.context.rootState.theUser.us_idTipoUsuario}/>
                    </div>
                </div>
            )
        }
        // Showing Login Or Register Page According to the condition
        else{
            return(
                <Login/>
            )
        }
    }
}
 
export default Usuarios;