import React from 'react';
import Navbar from './NavBar';
import {MyContext} from '../contexts/MyContext';
import Login from './Login';
import imgInicio from '../img/inicio.jpg';
import '../css/inicio.css';

class Inicio extends React.Component {

    static contextType = MyContext;

    constructor(){
        super();
        this.state = {

        }
    }

    componentDidMount = () => {
        document.title = 'Inicio - AdminGarteel';
    }

    render() { 
        if(this.context.rootState.isAuth === true){
            return <>
                <Navbar/>
                <div className="container inicio text-center">
                    <img src={imgInicio} alt="" className='imgInicio my-5' />
                </div>
                
                </>;
            }else{
                return (
                    <Login/>
                )
            }
    }
}
 
export default Inicio;