import React from 'react';
import Navbar from '../NavBar';
import EditarProyecto from './EditarProyecto';
import AvancesProyecto from './AvancesProyecto';

class Proyecto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    
    render() { 
        return ( 
            <>
                <Navbar/>
                <div className="container my-5">
                    <EditarProyecto idProyecto={this.props.idProyecto}/>
                    <AvancesProyecto idProyecto={this.props.idProyecto}/>
                </div>
            </>
        );
    }
}
 
export default Proyecto;